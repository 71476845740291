import React from "react";
import { useMediaQuery } from "react-responsive";
import { Navbar, Alignment, H1 } from "@blueprintjs/core";
import { ICategories } from "../domain";
import MenuBar from "./menu-bar";
import styles from "./index.module.scss";
import PopupMenu from "./popup-menu";

interface IProps
{
    categories: ICategories;
}

const Navigation: React.FunctionComponent<IProps> = ({ categories }) =>
{
    const smallScreen = useMediaQuery({ query: "(max-width: 1200px)" });

    return  <Navbar className="bp3-dark">

                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>
                        <H1 className={styles.title}>The Tamworth Photographer</H1>
                    </Navbar.Heading>
                </Navbar.Group>

                <Navbar.Group align={Alignment.RIGHT}>
                    { smallScreen && <PopupMenu categories={categories} /> }
                    { !smallScreen && <MenuBar categories={categories} /> }
                </Navbar.Group>
                
            </Navbar>;
};

export default Navigation;