import React from "react";
import Thumb from "./thumb";
import { IPhoto } from "../domain";

export interface IProps
{
    photo: IPhoto;
}

const PhotoThumb: React.FunctionComponent<IProps> = ({ photo }) => <Thumb background={photo} />;

export default PhotoThumb;