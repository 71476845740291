import React from "react";
import { H2, Card } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { categories } from "../data.generated";
import Thumb from "../components/thumb";
import "../shared.module.scss";
import styles from "./index.module.scss";

const Home: React.FunctionComponent = () =>
{
    const history = useHistory();
    
    return  <div className={`${styles.home} flex-grid`}>
                { Object.values(categories).map(category => 
                    <Card key={category.name} interactive={true} onClick={() => history.push(category.url)}>
                        <Thumb background={category.photos[0]} />

                        <H2 key={category.name}>{category.name}</H2>
                    </Card>
                )}
            </div>;
}

export default Home;