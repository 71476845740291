import React from "react";
import { Navbar } from "@blueprintjs/core";
import { ICategory } from "../../domain";
import Item from "./item";

interface IProps
{
    category: ICategory;
}

const CategoryItem: React.FunctionComponent<IProps> = ({ category }) =>
    <>
        <Navbar.Divider />
        <Item name={category.name} url={category.url} />
    </>;

export default CategoryItem;