import React from "react";
import { ICategory } from "../../domain";
import { IconNames } from "@blueprintjs/icons";
import Item from "./item";

interface IProps
{
    category: ICategory;
}

const CategoryItem: React.FunctionComponent<IProps> = ({ category }) =>
    <Item name={category.name} url={category.url} icon={IconNames.CAMERA} />;

export default CategoryItem;