import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import Navigation from "./navigation";
import { categories } from "./data.generated";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "./app.module.scss";

const App: React.FunctionComponent = () =>
    <Router>
        <header>
            <Navigation categories={categories} />
        </header>
        <main>
            <Routes />
        </main>
    </Router>;

export default App;