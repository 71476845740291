import React from "react";
import { MenuItem, Classes, IconName } from "@blueprintjs/core";
import { useHistory, useLocation } from "react-router-dom";

export interface IProps
{
    name: string;
    url: string;
    icon: IconName;
}

const Item: React.FunctionComponent<IProps> = ({ name, url, icon }) =>
{
    // Cannot use <NavLink> as it breaks the popover dismissing behaviour of <MenuItem>. Therefore pushing to history
    // ourselves rather than use MenuItem.url which would render a normal link with a normal redirect.
    const history = useHistory();
    const location = useLocation();

    // Yet to find the nice way to do this with React Router...
    const className = location.pathname === `/${url}` ? Classes.ACTIVE : undefined;

    return  <>
                <MenuItem className={className} text={name} icon={icon} onClick={() => history.replace(url)} />
            </>;
}

export default Item;