import React from "react";
import { Menu, MenuDivider } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ICategories } from "../../domain";
import Item from "./item";
import CategoryItem from "./category-item";

interface IProps
{
    categories: ICategories;
}

const PopoverContent: React.FunctionComponent<IProps> = ({ categories }) =>
    <Menu>
        <Item name="Home" url="" icon={IconNames.HOME} />
        <MenuDivider />
        { Object.values(categories).map(category => <CategoryItem key={category.name} category={category} />)}
    </Menu>;

export default PopoverContent;