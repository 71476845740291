import React from "react";
import { NavLink } from "react-router-dom";
import { Classes } from "@blueprintjs/core";

export interface IProps
{
    name: string;
    url: string;
}

const Item: React.FunctionComponent<IProps> = ({ name, url }) =>
    <NavLink className={`${Classes.BUTTON} ${Classes.MINIMAL}`} activeClassName={Classes.ACTIVE} to={url} exact={true}>{name}</NavLink>;

export default Item;