import React from "react";
import { ICategories } from "../../domain";
import Item from "./item";
import CategoryItem from "./category-item";

interface IProps
{
    categories: ICategories;
}

const MenuBar: React.FunctionComponent<IProps> = ({ categories }) =>
    <>
        <Item name="Home" url="" />
        { Object.values(categories).map(category => <CategoryItem key={category.name} category={category} />)}
    </>;

export default MenuBar;