import React from "react";
import { H2 } from "@blueprintjs/core";
import { ICategoryRoute, IPhoto } from "../domain";
import { useParams, Link } from "react-router-dom";
import { categories } from "../data.generated";
import PhotoThumb from "../components/photo-thumb";
import NotFound from "../not-found";
import Photo from "./photo";
import "../shared.module.scss";
import styles from "./index.module.scss";

const Category: React.FunctionComponent = () =>
{
    const { categoryUrl, photoHash } = useParams<ICategoryRoute>();
    
    const category = Object.values(categories).find(c => c.url === categoryUrl);

    if (category == null)
    {
        return <NotFound />;
    }

    let photo: IPhoto | undefined;
    if (photoHash != null)
    {
        photo = Object.values(category.photos).find(p => p.hash === photoHash);
        if (photo == null)
        {
            return <NotFound />;
        }
    }

    return  <div className={styles.category}>
                <H2>{category.name}</H2>
                <div className="flex-grid">
                    { category.photos.map(photo => 
                        <Link key={photo.hash} to={`/${category.url}/${photo.hash}`}>
                            <PhotoThumb photo={photo} />
                        </Link>) 
                    }
                </div>
                { photo != null && <Photo photo={photo} /> }
            </div>;
}

export default Category;