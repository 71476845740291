import React from "react";
import { Button, Popover, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ICategories } from "../../domain";
import PopoverContent from "./popover-content";

interface IProps
{
    categories: ICategories;
}

const PopupMenu: React.FunctionComponent<IProps> = ({ categories }) =>
    <Popover content={<PopoverContent categories={categories} />} position={Position.LEFT_BOTTOM}>
        <Button icon={IconNames.MENU} />
    </Popover>;

export default PopupMenu;