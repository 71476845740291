import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./home";
import Category from "./category";
import NotFound from "./not-found";

const Routes: React.FunctionComponent = () =>
    <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/:categoryUrl/:photoHash" component={Category} />
        <Route path="/:categoryUrl" component={Category} />
        <Route path="*" component={NotFound} status={404} />
    </Switch>;

export default Routes;