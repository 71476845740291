import React from "react";
import { Overlay } from "@blueprintjs/core";
import { IPhoto, ISize, photoUrl } from "../domain";
import { useHistory } from "react-router-dom";
import styles from "./photo.module.scss";

export interface IProps
{
    photo: IPhoto;
}

const totalPadding = 2 * 20;

function getMediaQuery(size: ISize)
{
    return `(min-width: ${size.width + totalPadding}px) and (min-height: ${size.height + totalPadding}px)`;
}

const Photo: React.FunctionComponent<IProps> = ({ photo }) =>
{
    const history = useHistory();

    const defaultSize = photo.sizes[0];
    const otherSizes = photo.sizes.slice(1);

    return  <Overlay className={styles.photo} isOpen={true} onClose={() => history.push(`/${photo.category.url}`)}>
                <picture>
                    { otherSizes.map(size => <source key={`${size.width}x${size.height}`} media={getMediaQuery(size)} srcSet={photoUrl(photo, size)} />) }
                    <img alt="Large View" src={photoUrl(photo, defaultSize)} />
                </picture>
            </Overlay>;
}

export default Photo;