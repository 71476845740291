export interface ISize
{
    width: number;
    height: number;
}

export interface ICategory
{
    name: string;
    url: string;
    photos: Array<IPhoto>;
}

export interface ICategoryRoute
{
    categoryUrl: string;
    photoHash?: string;
}

export interface ICategories
{
    [name: string]: ICategory;
}

export function createCategory(name: string, url: string): ICategory
{
    return {
        name,
        url,
        photos: []
    };
}

export interface IPhoto
{
    category: ICategory;
    hash: string;
    sizes: Array<ISize>;
}

function photoUrlRoot(photo: IPhoto): string
{
    return `${process.env.PUBLIC_URL}/images/${photo.category.url}/${photo.hash}/`;
}

export function photoUrl(photo: IPhoto, size: ISize): string
{
    return `${photoUrlRoot(photo)}${size.width}-${size.height}.jpg`;
}

export function thumbUrl(photo: IPhoto): string
{
    return `${photoUrlRoot(photo)}thumb.jpg`;
}

export function createPhoto(category: ICategory, hash: string, ...sizes: [number, number][]): IPhoto
{
    const photo: IPhoto =
    {
        category,
        hash,
        sizes: sizes.map(size => ({ width: size[0], height: size[1] }))
    };

    category.photos.push(photo);
    return photo;
}