import { ICategories, createCategory, createPhoto, IPhoto } from "./domain";

export const categories: ICategories =
{
	"35mm-film": createCategory("35mm Film", "35mm-film"),
	"digital": createCategory("Digital", "digital"),
	"large-format-film": createCategory("Large Format Film", "large-format-film"),
	"medium-format-film": createCategory("Medium Format Film", "medium-format-film")
};

export const photos: Array<IPhoto> =
[
	createPhoto(categories["35mm-film"], "3f9fea395d35f022", [778, 1080], [648, 900], [553, 768], [518, 720], [389, 540], [346, 480], [259, 360], [173, 240], [130, 180], [104, 144]),
	createPhoto(categories["35mm-film"], "ef3ba65ceb3febe9", [756, 1080], [630, 900], [538, 768], [504, 720], [378, 540], [336, 480], [252, 360], [168, 240], [126, 180], [101, 144]),
	createPhoto(categories["35mm-film"], "fc5a9ebf0bb34394", [862, 1080], [719, 900], [613, 768], [575, 720], [431, 540], [383, 480], [287, 360], [192, 240], [144, 180], [115, 144]),
	createPhoto(categories["35mm-film"], "5c650b5ee58534e2", [790, 1080], [658, 900], [562, 768], [527, 720], [395, 540], [351, 480], [263, 360], [175, 240], [132, 180], [105, 144]),
	createPhoto(categories["35mm-film"], "ecf56eca63d38a09", [820, 1080], [684, 900], [583, 768], [547, 720], [410, 540], [365, 480], [273, 360], [182, 240], [137, 180], [109, 144]),
	createPhoto(categories["digital"], "fead1ada86373d38", [1635, 1080], [1362, 900], [1163, 768], [1090, 720], [817, 540], [720, 476], [545, 360], [363, 240], [272, 180], [218, 144]),
	createPhoto(categories["digital"], "f1baf7e881d86ffa", [1667, 1080], [1389, 900], [1185, 768], [1111, 720], [833, 540], [720, 467], [556, 360], [370, 240], [278, 180], [222, 144]),
	createPhoto(categories["digital"], "1ed65ef726654d8e", [1728, 1080], [1440, 900], [1229, 768], [1152, 720], [864, 540], [720, 450], [576, 360], [384, 240], [288, 180], [230, 144]),
	createPhoto(categories["digital"], "ca4c44cd62f35479", [720, 1080], [600, 900], [512, 768], [480, 720], [360, 540], [320, 480], [240, 360], [160, 240], [120, 180], [96, 144]),
	createPhoto(categories["digital"], "bd63fe5af4186829", [778, 509], [778, 509], [778, 509], [778, 509], [778, 509], [720, 471], [550, 360], [367, 240], [275, 180], [220, 144]),
	createPhoto(categories["digital"], "cfbf96883cdf9af0", [1920, 1051], [1600, 876], [1366, 748], [1280, 701], [960, 525], [720, 394], [640, 350], [426, 233], [320, 175], [256, 140]),
	createPhoto(categories["digital"], "23f3a3b5ca7e5496", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "24af21d295a9c9aa", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "a187c7265b734d4d", [1489, 1080], [1241, 900], [1059, 768], [993, 720], [744, 540], [662, 480], [496, 360], [331, 240], [248, 180], [198, 144]),
	createPhoto(categories["digital"], "617caff653d90166", [720, 1080], [600, 900], [512, 768], [480, 720], [360, 540], [320, 480], [240, 360], [160, 240], [120, 180], [96, 144]),
	createPhoto(categories["digital"], "56c363789bf3283c", [1395, 1080], [1162, 900], [992, 768], [930, 720], [697, 540], [620, 480], [465, 360], [310, 240], [232, 180], [186, 144]),
	createPhoto(categories["digital"], "443d2c3a76f8c775", [850, 454], [850, 454], [850, 454], [850, 454], [850, 454], [720, 385], [640, 342], [426, 228], [320, 171], [256, 137]),
	createPhoto(categories["digital"], "d7fc14897d9d62b1", [1920, 1056], [1600, 880], [1366, 752], [1280, 704], [960, 528], [720, 396], [640, 352], [426, 234], [320, 176], [256, 141]),
	createPhoto(categories["digital"], "a7f813585fc44f82", [1542, 1080], [1285, 900], [1096, 768], [1028, 720], [771, 540], [685, 480], [514, 360], [342, 240], [257, 180], [205, 144]),
	createPhoto(categories["digital"], "e0acd4e3fd0fd7c1", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "e9b9109d03f1eb25", [1228, 1080], [1023, 900], [873, 768], [819, 720], [614, 540], [546, 480], [409, 360], [273, 240], [205, 180], [164, 144]),
	createPhoto(categories["digital"], "25f2c8544c4413ac", [1579, 1080], [1316, 900], [1123, 768], [1053, 720], [789, 540], [702, 480], [526, 360], [351, 240], [263, 180], [210, 144]),
	createPhoto(categories["digital"], "742b7cfc29ef4f61", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "263da06bcc376529", [850, 515], [850, 515], [850, 515], [850, 515], [850, 515], [720, 436], [594, 360], [396, 240], [297, 180], [238, 144]),
	createPhoto(categories["digital"], "4c2c3827c70c85ec", [850, 567], [850, 567], [850, 567], [850, 567], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "812ef8771ebc81de", [1920, 1046], [1600, 871], [1366, 744], [1280, 697], [960, 523], [720, 392], [640, 349], [426, 232], [320, 174], [256, 139]),
	createPhoto(categories["digital"], "215a9a80f990d990", [1920, 1069], [1600, 891], [1366, 761], [1280, 713], [960, 535], [720, 401], [640, 356], [426, 237], [320, 178], [256, 142]),
	createPhoto(categories["digital"], "7087480c42336754", [1627, 1080], [1356, 900], [1157, 768], [1085, 720], [813, 540], [720, 478], [542, 360], [361, 240], [271, 180], [217, 144]),
	createPhoto(categories["digital"], "4bb4590344f3e3fe", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "1064d218e1c50a32", [720, 1080], [600, 900], [512, 768], [480, 720], [360, 540], [320, 480], [240, 360], [160, 240], [120, 180], [96, 144]),
	createPhoto(categories["digital"], "215fc0ca16ae6606", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "6243b2c66355b435", [1815, 1080], [1512, 900], [1290, 768], [1210, 720], [907, 540], [720, 428], [605, 360], [403, 240], [302, 180], [242, 144]),
	createPhoto(categories["digital"], "40b74c6a85e067a1", [1630, 1080], [1358, 900], [1159, 768], [1087, 720], [815, 540], [720, 477], [543, 360], [362, 240], [272, 180], [217, 144]),
	createPhoto(categories["digital"], "acf931bf1d20f96b", [1920, 1061], [1600, 884], [1366, 755], [1280, 707], [960, 531], [720, 398], [640, 354], [426, 235], [320, 177], [256, 141]),
	createPhoto(categories["digital"], "209b9ca15b74f782", [1704, 1080], [1420, 900], [1212, 768], [1136, 720], [852, 540], [720, 456], [568, 360], [379, 240], [284, 180], [227, 144]),
	createPhoto(categories["digital"], "6294c45bdbb250aa", [1565, 1080], [1304, 900], [1113, 768], [1043, 720], [782, 540], [695, 480], [522, 360], [348, 240], [261, 180], [209, 144]),
	createPhoto(categories["digital"], "05d5ca90d937c16a", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "53c32a3204996a54", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "ec12cf0311f1983d", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "837806a1c2fc4a8c", [1656, 1080], [1380, 900], [1178, 768], [1104, 720], [828, 540], [720, 470], [552, 360], [368, 240], [276, 180], [221, 144]),
	createPhoto(categories["digital"], "75c07984edaeee12", [1770, 1080], [1475, 900], [1258, 768], [1180, 720], [885, 540], [720, 439], [590, 360], [393, 240], [295, 180], [236, 144]),
	createPhoto(categories["digital"], "28fc69290fdcb5cd", [1642, 1080], [1368, 900], [1167, 768], [1094, 720], [821, 540], [720, 474], [547, 360], [365, 240], [274, 180], [219, 144]),
	createPhoto(categories["digital"], "bcf685013bcb5292", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "5b90ed2fb994a6cc", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "00fe9a803a247329", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "b6fa2dbb20078790", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "0e7dcd4a2ae6af2d", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "7bcaa2b4d1882523", [1269, 1080], [1057, 900], [902, 768], [846, 720], [634, 540], [564, 480], [423, 360], [282, 240], [211, 180], [169, 144]),
	createPhoto(categories["digital"], "74413a12b93c73de", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "e4277378715fbdd4", [698, 1080], [581, 900], [496, 768], [465, 720], [349, 540], [310, 480], [232, 360], [155, 240], [116, 180], [93, 144]),
	createPhoto(categories["digital"], "59749cad4f3917f6", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "7d192f1a93058c96", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "c1fcfa0fcdac4be4", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "0cf8b335020d58e7", [720, 1080], [600, 900], [512, 768], [480, 720], [360, 540], [320, 480], [240, 360], [160, 240], [120, 180], [96, 144]),
	createPhoto(categories["digital"], "b9a7945c2b31019f", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "7f57d6b4938754a3", [1622, 1080], [1352, 900], [1154, 768], [1082, 720], [811, 540], [720, 479], [541, 360], [361, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "8b3538ef26287d09", [1864, 1080], [1553, 900], [1326, 768], [1243, 720], [932, 540], [720, 417], [621, 360], [414, 240], [310, 180], [249, 144]),
	createPhoto(categories["digital"], "3db9d52bca5db3ee", [1389, 1080], [1158, 900], [988, 768], [926, 720], [695, 540], [617, 480], [463, 360], [309, 240], [232, 180], [185, 144]),
	createPhoto(categories["digital"], "7b3f23067802ae4e", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "39439887cd8743bc", [1604, 1080], [1336, 900], [1140, 768], [1069, 720], [802, 540], [713, 480], [535, 360], [356, 240], [267, 180], [214, 144]),
	createPhoto(categories["digital"], "fcd207fd02e801dc", [1764, 1080], [1470, 900], [1254, 768], [1176, 720], [882, 540], [720, 441], [588, 360], [392, 240], [294, 180], [235, 144]),
	createPhoto(categories["digital"], "c6d5c2eafde28a27", [1777, 1080], [1481, 900], [1264, 768], [1185, 720], [888, 540], [720, 437], [592, 360], [395, 240], [296, 180], [237, 144]),
	createPhoto(categories["digital"], "ed2ce7a37d291509", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "62bd780e25790548", [1920, 946], [1600, 789], [1366, 673], [1280, 631], [960, 473], [720, 355], [640, 315], [426, 210], [320, 158], [256, 126]),
	createPhoto(categories["digital"], "a3f1edc4afa03c23", [1699, 1080], [1416, 900], [1208, 768], [1133, 720], [850, 540], [720, 458], [566, 360], [377, 240], [283, 180], [226, 144]),
	createPhoto(categories["digital"], "2eacb4ba5a83f30b", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "9eef6b353246e843", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "85ee5c47256c52af", [1589, 1080], [1324, 900], [1130, 768], [1059, 720], [795, 540], [706, 480], [530, 360], [353, 240], [265, 180], [212, 144]),
	createPhoto(categories["digital"], "8bd82c56a0f3114b", [1920, 1017], [1600, 847], [1366, 723], [1280, 678], [960, 508], [720, 381], [640, 339], [426, 226], [320, 169], [256, 135]),
	createPhoto(categories["digital"], "1a02a2f1f62d8b4f", [720, 1080], [600, 900], [512, 768], [480, 720], [360, 540], [320, 480], [240, 360], [160, 240], [120, 180], [96, 144]),
	createPhoto(categories["digital"], "2e4c36969c5749b1", [1419, 1080], [1183, 900], [1009, 768], [946, 720], [710, 540], [631, 480], [473, 360], [315, 240], [237, 180], [189, 144]),
	createPhoto(categories["digital"], "c874ebf89650e098", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "38a9c02da43da976", [1920, 1073], [1600, 895], [1366, 764], [1280, 716], [960, 537], [720, 403], [640, 358], [426, 238], [320, 179], [256, 143]),
	createPhoto(categories["digital"], "a431ff81f3ea3945", [1509, 1080], [1258, 900], [1073, 768], [1006, 720], [755, 540], [671, 480], [503, 360], [335, 240], [251, 180], [201, 144]),
	createPhoto(categories["digital"], "81636193a71a7fda", [1920, 1051], [1600, 876], [1366, 748], [1280, 701], [960, 525], [720, 394], [640, 350], [426, 233], [320, 175], [256, 140]),
	createPhoto(categories["digital"], "b56e52aaaa7b6c3d", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "caf11a9e759b54e7", [1380, 1080], [1150, 900], [981, 768], [920, 720], [690, 540], [613, 480], [460, 360], [307, 240], [230, 180], [184, 144]),
	createPhoto(categories["digital"], "707a4b14230ca30a", [1833, 1080], [1527, 900], [1303, 768], [1222, 720], [916, 540], [720, 424], [611, 360], [407, 240], [305, 180], [244, 144]),
	createPhoto(categories["digital"], "e09967492a5f893a", [1739, 1080], [1449, 900], [1237, 768], [1160, 720], [870, 540], [720, 447], [580, 360], [387, 240], [290, 180], [232, 144]),
	createPhoto(categories["digital"], "8f27a8545859d823", [720, 1080], [600, 900], [512, 768], [480, 720], [360, 540], [320, 480], [240, 360], [160, 240], [120, 180], [96, 144]),
	createPhoto(categories["digital"], "0ea585e850ae3e50", [981, 1080], [818, 900], [698, 768], [654, 720], [491, 540], [436, 480], [327, 360], [218, 240], [164, 180], [131, 144]),
	createPhoto(categories["digital"], "3ef6a288e352908a", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "468e961e0e10aba9", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "623a3cc26a8bf76b", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "13394a2b0533233c", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "b1ee9af067275dab", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "b761b45022a5847d", [1799, 1080], [1500, 900], [1280, 768], [1200, 720], [900, 540], [720, 432], [600, 360], [400, 240], [300, 180], [240, 144]),
	createPhoto(categories["digital"], "d923ef04cbb7bc05", [1779, 1080], [1482, 900], [1265, 768], [1186, 720], [889, 540], [720, 437], [593, 360], [395, 240], [296, 180], [237, 144]),
	createPhoto(categories["digital"], "9a0c279849f631cd", [1882, 1080], [1568, 900], [1338, 768], [1255, 720], [941, 540], [720, 413], [627, 360], [418, 240], [314, 180], [251, 144]),
	createPhoto(categories["digital"], "8cf820f7731805a2", [1620, 1080], [1350, 900], [1152, 768], [1080, 720], [810, 540], [720, 480], [540, 360], [360, 240], [270, 180], [216, 144]),
	createPhoto(categories["digital"], "8291eccbccbd5589", [919, 1080], [766, 900], [653, 768], [612, 720], [459, 540], [408, 480], [306, 360], [204, 240], [153, 180], [122, 144]),
	createPhoto(categories["digital"], "b5272f2af56aa03c", [1784, 1080], [1487, 900], [1269, 768], [1190, 720], [892, 540], [720, 436], [595, 360], [397, 240], [297, 180], [238, 144]),
	createPhoto(categories["large-format-film"], "fc3729ccc0468389", [855, 1080], [712, 900], [608, 768], [570, 720], [427, 540], [380, 480], [285, 360], [190, 240], [142, 180], [114, 144]),
	createPhoto(categories["large-format-film"], "c4f6ee23428b4387", [856, 1080], [713, 900], [609, 768], [570, 720], [428, 540], [380, 480], [285, 360], [190, 240], [142, 180], [114, 144]),
	createPhoto(categories["large-format-film"], "311b4cbd1862cf8e", [1365, 1080], [1137, 900], [970, 768], [910, 720], [682, 540], [606, 480], [455, 360], [303, 240], [227, 180], [182, 144]),
	createPhoto(categories["large-format-film"], "a22432a492d6637b", [835, 1080], [696, 900], [594, 768], [557, 720], [418, 540], [371, 480], [278, 360], [186, 240], [139, 180], [111, 144]),
	createPhoto(categories["medium-format-film"], "2f3f4590ae48eb73", [1459, 1080], [1216, 900], [1038, 768], [973, 720], [730, 540], [649, 480], [486, 360], [324, 240], [243, 180], [195, 144]),
	createPhoto(categories["medium-format-film"], "70bbb6141ac55aaf", [1557, 1080], [1298, 900], [1107, 768], [1038, 720], [779, 540], [692, 480], [519, 360], [346, 240], [260, 180], [208, 144]),
	createPhoto(categories["medium-format-film"], "1f5f79d4c2706c56", [785, 1080], [654, 900], [558, 768], [523, 720], [392, 540], [349, 480], [261, 360], [174, 240], [131, 180], [105, 144])
];