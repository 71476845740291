import React from "react";
import styles from "./thumb.module.scss";
import { IPhoto, thumbUrl } from "../domain";

export interface IProps
{
    className?: string;
    background?: IPhoto;
}

function buildClassName(className?: string)
{
    return className != null ? `${styles.thumb} ${className}` : styles.thumb;
}

const Thumb: React.FunctionComponent<IProps> = ({ background, className, children }) =>
    <div className={buildClassName(className)} style={background != null ? { backgroundImage: `url('${thumbUrl(background)}')` } : {} }>
        {children}
    </div>;

export default Thumb;